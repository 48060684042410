import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSelectModule, MatInputModule, MatTooltipModule, MatDividerModule, MatDialogModule,
  MatDatepickerModule, MatGridListModule, MatIconModule, MatButtonModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { DeviceDetectorModule } from 'ngx-device-detector';

import { AppComponent } from './app.component';
import { CalculatorComponent, CalculatorSubmitDialogComponent } from './calculator/calculator.component';
import { SalesforceHashLocationStrategy } from './util/sf-path-location-strategy';

@NgModule({
  declarations: [
    AppComponent,
    CalculatorComponent,
    CalculatorSubmitDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatInputModule,
    MatTooltipModule,
    MatDividerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatGridListModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [{ provide: LocationStrategy, useClass: SalesforceHashLocationStrategy }],
  bootstrap: [AppComponent],
  entryComponents: [CalculatorSubmitDialogComponent]
})
export class AppModule { }

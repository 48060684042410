import { Injectable } from '@angular/core';
import { Location, PlatformLocation, LocationStrategy, LocationChangeListener } from '@angular/common';

declare var getSfPageRoot: () => string;


/*
    A location strategy that works similar to the HashLocationStrategy but
    works better for Salesforce
    Regular HashlocationStratgey makes paths like
    http://c67.salesforce.com/#/search/result
    This makes paths like
    http://c67.salesforce.com/apex/pagename#search/result
*/
@Injectable()
export class SalesforceHashLocationStrategy extends LocationStrategy {
    constructor(
        private platformLocation: PlatformLocation) {
        super();
    }

    onPopState(fn: LocationChangeListener): void {
        this.platformLocation.onPopState(fn);
        this.platformLocation.onHashChange(fn);
    }

    getBaseHref(): string {
        return getSfPageRoot();
    }

    path(includeHash: boolean = false): string {
        // The hash value is always prefixed with a `#`
        // and if it is empty then it will stay empty
        let path = this.platformLocation.hash;
        if (!path) {
            path = '#';
        }

        return path.substring(1);
    }

    prepareExternalUrl(internal: string): string {
        let url = internal;
        if (url.endsWith('/')) {
            url = url.substr(0, url.length - 1);
        }

        return this.getBaseHref() + '#' + url;
    }

    pushState(state: any, title: string, path: string, queryParams: string) {
        const url = this.prepareExternalUrl(path + Location.normalizeQueryParams(queryParams));
        this.platformLocation.pushState(state, title, url);
    }

    replaceState(state: any, title: string, path: string, queryParams: string) {
        const url = this.prepareExternalUrl(path + Location.normalizeQueryParams(queryParams));
        this.platformLocation.replaceState(state, title, url);
    }

    forward(): void { this.platformLocation.forward(); }

    back(): void { this.platformLocation.back(); }
}

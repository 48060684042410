import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IeTRFService } from '../interfaces/ie-trfservice';
import { Arguments } from 'spnmr-library/lib/index';
import { SalesforceLocatorService } from './salesforce-locator.service';

@Injectable({
  providedIn: 'root'
})
export class ETRFService implements IeTRFService {
  public static ageKey = 'age';
  public static cancerKey = 'cancer';
  public static dobKey = 'dateOfBirth';
  public static probabilityKey = 'malignantProbability';
  public static diameterKey = 'noduleDiameter';
  public static resultsKey = 'results';
  public static smokerKey = 'smoker';
  public static spiculationKey = 'spiculation';
  public static lobeKey = 'upperLobe';
  private static ISDTP = 'isdtp';
  private static P1 = 'p1';
  private static FRAME_ORIGIN = 'sfdcIFrameOrigin';
  private static PROTOCOL_SEPARATOR = '//';
  private serviceEndpoint = 's/request-nodify';
  private embeddedEndpoint = 'apex/PortalTestRequestXL2';

  constructor(
    private salesforceLocatorService: SalesforceLocatorService,
    private httpClient: HttpClient) {
  }

  public static getAbsoluteDomain(): string {
    return window.location.protocol + ETRFService.PROTOCOL_SEPARATOR + window.location.host;
  }

  public submit(args: Arguments): Promise<boolean> {
    console.log('etrf service submit called');
    const endpoint = this.salesforceLocatorService.getHost() + this.serviceEndpoint;
    console.log(endpoint);
    const promise = new Promise<boolean>((resolve, reject) => {
      this.httpClient.post(endpoint, args)
        .toPromise()
        .then(
          res => { // Success
            console.log('response: ' + res.toString());
            resolve(true);
          },
          msg => { // Error
            reject(msg);
          }
        );
    });
    return promise;
  }

  public getGETUrl(args: Arguments, embedded: boolean): string {
    let endpoint = this.salesforceLocatorService.getHost();
    endpoint += embedded ? this.embeddedEndpoint : this.serviceEndpoint;
    let params = new HttpParams();
    params = params.append(ETRFService.ISDTP, ETRFService.P1);
    params = params.append(ETRFService.FRAME_ORIGIN, ETRFService.getAbsoluteDomain());
    params = params.append(ETRFService.ageKey, args.age.toString())
      .append(ETRFService.dobKey, args.dateOfBirth ? args.dateOfBirth.format('L') : '')
      .append(ETRFService.cancerKey, args.cancer.value.toString())
      .append(ETRFService.probabilityKey, args.malignantProbability.toString())
      .append(ETRFService.diameterKey, args.noduleDiameter.toString())
      .append(ETRFService.resultsKey, args.results)
      .append(ETRFService.smokerKey, args.smoker.value.toString())
      .append(ETRFService.spiculationKey, args.spiculation.value.toString())
      .append(ETRFService.lobeKey, args.upperLobe.value.toString());
    endpoint += '?' + params.toString();
    return endpoint;
  }
}

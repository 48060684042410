import { Component, ElementRef } from '@angular/core';
import 'hammerjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Biodesix Web Application';
  private embedded: boolean;

  constructor(elm: ElementRef) {
    this.embedded = elm.nativeElement.getAttribute('embedded') === 'true';
  }

  public isEmbedded(): boolean {
    return this.embedded;
  }
}

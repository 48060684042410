import { Component, OnInit, Attribute } from '@angular/core';
import { YesNoMapping, SmokerMapping, CancerMapping } from './mappings';
import * as moment from 'moment';
import { ETRFService } from '../services/implementations/e-trf.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Arguments, SPNMRCalculator, YesNo } from 'spnmr-library/lib/index';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-calculator-submit-dialog',
  templateUrl: 'calculator-submit-dialog.html',
})
export class CalculatorSubmitDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CalculatorSubmitDialogComponent>) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnInit {
  public title = 'Solitary Pulmonary Nodule Malignancy Risk Calculator';
  public arguments: Arguments;
  public smokerChoices: { value: YesNo; display: string; }[];
  public cancerChoices: { value: YesNo; display: string; }[];
  public yesNoChoices: { value: YesNo; display: string; }[];
  private minDate: moment.Moment;
  private maxDate: moment.Moment;
  private startDate: moment.Moment;
  private colorMap: Map<boolean, string>;
  private patientEligible: boolean;

  private ineligibleHeader = 'Nodify Testing is intended for patients who:';

  private description =
    'The Mayo Clinic Model was developed and validated to ' +
    'assess the risk of lung cancer in a population of incidental ' +
    'pulmonary nodules with a lung cancer rate of 23%. If the ' +
    'patient has multiple nodules, the clinical inputs for this calculation ' +
    'should reflect the primary nodule of concern. See footnote 1.';
  private citation =
    'Swensen SJ, Silverstein MD, Ilstrup DM, et. al. ' +
    'The probability of malignancy in solitary pulmonary nodules. ' +
    'Application to small radiologically indeterminate nodules. ' +
    'Arch Intern Med. 1997 Apr 28; 157(8):849-55.';
  private validity =
    '*This risk model is not validated for those with a history of prior lung cancer or extrathoracic cancer within the last 5 years.';

  public dobTooltip = `Date of birth and today’s date can be used to calculate age in years or age can be input directly.`;
  public smokerTooltip = `A never smoker is defined as an adult who has smoked less than 100 cigarettes in his or her lifetime.`;
  public cancerTooltip = `History of cancer indicates a history of extrathoracic cancer more than 5 years prior.`;
  public diameterTooltip = `Diameter measured on the nodule of concern.`;
  public locationTooltip =
    `Upper lobe location indicates that the nodule of concern is located in the upper lobe of the left or right lung.`;
  public spiculatedTooltip = `Spiculation indicates that the nodule of concern has non-smooth edge characteristics.
Other terms commonly used in place of spiculation include “stellate” and “irregular”.`;

  constructor(
    @Attribute('embedded') public embedded: string = 'false',
    private eTRFService: ETRFService,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog
  ) {
    this.arguments = new Arguments();
    this.addEnumMappings();
    this.setDefaults();
  }

  private addEnumMappings(): void {
    this.smokerChoices = SmokerMapping;
    this.cancerChoices = CancerMapping;
    this.yesNoChoices = YesNoMapping;
    this.colorMap = new Map<boolean, string>();
    this.colorMap.set(true, '#009A44');
    this.colorMap.set(false, '#AB2328');
    this.colorMap.set(null, '#72829F');
  }

  private setDefaults(): void {
    this.arguments.smoker = this.smokerChoices.find((e) => e.value === YesNo.yes);
    this.arguments.cancer = this.cancerChoices.find((e) => e.value === YesNo.no);
    this.arguments.spiculation = this.yesNoChoices.find((e) => e.value === YesNo.no);
    this.arguments.upperLobe = this.yesNoChoices.find((e) => e.value === YesNo.no);
    this.minDate = moment().subtract(150, 'years');
    this.maxDate = moment();
    this.startDate = moment().subtract(50, 'years');
    this.arguments.probabilityValue = null;
  }

  ngOnInit(): void {
  }

  public isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  public getMinDate(): moment.Moment {
    return this.minDate;
  }

  public getMaxDate(): moment.Moment {
    return this.maxDate;
  }

  public getStartDate(): moment.Moment {
    return this.startDate;
  }

  public getTileColor(): string {
    const isTileColor = !(this.arguments.age && this.arguments.noduleDiameter) ? SPNMRCalculator.getQualifies(this.arguments) 
    : SPNMRCalculator.checkMedicareEligibility(this.arguments).length === 0;
    return this.colorMap.get(isTileColor);
  }

  public getPatientEligible(): boolean {
    return this.patientEligible;
  }

  public getFilledEquations(): string {
    return `X (${this.arguments.results ? this.arguments.results : 'Awaiting Inputs'}) =
    (0.0391 * ${this.arguments.age ? this.arguments.age : 'Awaiting Date of Birth Input'}) +
    (0.7917 * ${this.arguments.smoker.value}) +
    (1.3388 * ${this.arguments.cancer.value}) +
    (0.1274 * ${this.arguments.noduleDiameter ? this.arguments.noduleDiameter : 'Awaiting Nodule Diameter Input'}) +
    (1.0407 * ${this.arguments.spiculation.value}) +
    (0.7838 * ${this.arguments.upperLobe.value}) -
    6.8272;
    Malignant Probability
    ${this.arguments.malignantProbability ? this.arguments.malignantProbability + '%' : '(Awaiting Inputs)'} =
    100 * e(${this.arguments.results ? this.arguments.results : 'Awaiting Inputs'}) /
    (1 + e(${this.arguments.results ? this.arguments.results : 'Awaiting Inputs'}))`;
  }

  public getDescription(): string {
    return this.description;
  }

  public getValidity(): string {
    return this.validity;
  }

  public getCitation(): string {
    return this.citation;
  }

  public validateArguments(): boolean {
    return SPNMRCalculator.validateArguments(this.arguments);
  }

  public onChangeAge() {
    this.arguments.displayDob = undefined;
    this.arguments.displayAge = this.arguments.displayAge > 0 ? Math.floor(this.arguments.displayAge) : NaN;
    this.performSPNMRCalculation();
  }

  public onChangeDob() {
    const age = this.calculateAge(this.arguments.displayDob);
    this.arguments.displayAge = age;
    this.performSPNMRCalculation();
  }

  public calculateAge(dateOfBirth) {
    const dob = moment(dateOfBirth);
    const age = dob.isValid() ?
      Math.floor(moment().diff(dob, 'years', true)) : NaN;
    return age;
  }

  public performSPNMRCalculation(): Arguments {

    this.patientEligible = null;
    this.arguments.dateOfBirth = this.arguments.displayDob;
    this.arguments.age = this.arguments.displayAge;
    if (this.arguments.dateOfBirth > this.getMaxDate()) {
      this.arguments.dateOfBirth = this.arguments.dateOfBirth.subtract(100, 'years');
    }

    this.arguments = SPNMRCalculator.performSPNMRCalculation(this.arguments);

    if (this.validateArguments()) {
      this.patientEligible = this.isEligible();
    }
    return this.arguments;
  }

  private isEligible(): boolean {
    return SPNMRCalculator.checkMedicareEligibility(this.arguments).length === 0;
  }

  private isLogoEnabled(): boolean {
    return this.validateArguments() && this.isEligible();
  }

  public getEligibilityExplanation(): string {
    const reasons = SPNMRCalculator.checkMedicareEligibility(this.arguments);
    if (reasons.length === 0) {
      return null;
    }

    let display = this.ineligibleHeader;
    reasons.forEach((value) => { display += '\n- ' + value; });
    return display;
  }

  public isEmbedded() {
    return this.embedded;
  }

  public getSubmitURL(): string {
    if (!this.validateArguments()) {
      return null;
    }

    return this.eTRFService.getGETUrl(this.arguments, this.isEmbedded() === 'true');
  }

  public submitETRF(): void {
    if (this.isEligible()) {
      window.location.href = this.getSubmitURL();
    } else {
      const dialogRef = this.dialog.open(CalculatorSubmitDialogComponent, {
        data: { reasons: SPNMRCalculator.checkMedicareEligibility(this.arguments) }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          window.location.href = this.getSubmitURL();
        }
      });
    }
  }
}

import { YesNo } from 'spnmr-library/lib/index';

export const YesNoMapping = [
  { value: YesNo.yes, display: 'Yes (1)' },
  { value: YesNo.no, display: 'No (0)' }
];

export const YesNoNSMapping = [
  { value: YesNo.yes, display: 'Yes (1)' },
  { value: YesNo.no, display: 'No (0)' },
  { value: YesNo.notSpecified, display: 'Not Specified (0)' }
];

export const SmokerMapping = [
  { value: YesNo.yes, display: 'Current or Former (1)' },
  { value: YesNo.no, display: 'Never smoker (0)' }
];

export const CancerMapping = [
  { value: YesNo.yes, display: 'Yes (1)' },
  { value: YesNo.no, display: 'None (0)' }
];

import { Injectable } from '@angular/core';
import { IServiceLocator } from '../interfaces/iservice-locator';

@Injectable({
  providedIn: 'root'
})
export class SalesforceLocatorService implements IServiceLocator {
  // The current implementation assumes we are hosting on SF directly.
  private static endpoint = '/';

  constructor() { }

  getHost(): string {
    return SalesforceLocatorService.endpoint;
  }
}
